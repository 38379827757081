<div class="container">
  <h2 class="d-flex justify-content-center">{{header}}</h2>
  <br />
  <div class="text-center">
    HobbyShare.app is a social platform where you can share your projects and make use of your own set of skills.
    <br />
    You can add contributors to your projects, or ask to join a project yourself.
    <br />
    Check out the home page for the newest projects.
  </div>
  <br />
</div>
