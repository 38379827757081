<div class="container">

  <h1>{{heading}}</h1>

  <!--Closable alert if set-->
  <ngb-alert *ngIf="alert !== undefined"
             [type]="alert.type"
             (close)="setAlert(-1)"
             style="white-space: pre-line">
    {{alert.message}}
  </ngb-alert>

  <a routerLink="/forgot/username"
     class="text-secondary"
     style="font-size: 0.8rem">
    Forgot username?
  </a>

  <div class="form-group row">
    <div class="col-sm-5 mt-2">

      <div class="input-group">

        <div class="input-group-prepend">
          <button class="btn btn-primary"
                  (click)="forgotPassword()">
            Reset
          </button>
        </div>

        <input type="text"
               [(ngModel)]="username"
               class="form-control"
               placeholder="Username" />
      </div>

    </div>
  </div>

</div>
