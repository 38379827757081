<div class="container">

  <div *ngIf="user404 === false">
    <br />
    <div class="row">
      <div class="col-auto">

        <div *ngIf="pic === undefined"
             class="bg-secondary rounded-circle border border-primary row no-gutters"
             style="width: 100px; height: 100px;">

          <h1 class="col d-flex justify-content-center align-self-center"
              style="font-size: 400%;">
            {{username.charAt(0) | uppercase}}
          </h1>

        </div>

        <div *ngIf="pic !== undefined"
             class="border border-success row no-gutters"
             style="width: 100px; height: 100px;">
          <div class="col align-self-center d-flex justify-content-center">
            <img [src]="pic"
                 style="display: inline-block; max-width: 100%; max-height: 100%;" />
          </div>
        </div>


      </div>

      <div class="align-self-center col">
        <h1>{{fname}} {{lname}}</h1>
      </div>

      <div *ngIf="owner"
           class="align-self-center col-auto">
        <a class="btn btn-secondary float-right"
           routerLink="/settings">
          Settings
        </a>
      </div>

    </div>

    <h2>{{username}}</h2>
    <div *ngIf="email !== undefined && email !== ''">
      {{email}}
    </div>
    <br />

    <!--Skill builder component-->
    <h4>Skills:</h4>
    <app-skills *ngIf="skills !== undefined"
                [skills]="skills"
                [readOnly]="true"></app-skills>
    <br />

    <div class="row mb-3">

      <h4 class="col">Projects:</h4>

      <div class="col">
        <a *ngIf="owner"
           class="btn btn-secondary float-right"
           routerLink="/project/add">
          Create Project
        </a>
      </div>

    </div>

    <div *ngIf="projects">
      <div *ngFor="let project of projects; index as i">
        <app-project-button [i]="i"
                            [projects]="projects"
                            [owner]="true">
        </app-project-button>
      </div>
    </div>

    <br />
    <div class="row">
      <h4 class="col">
        Contributing:
      </h4>
    </div>

    <div *ngIf="contributing">
      <div *ngFor="let project of contributing; index as i">
        <app-project-button [i]="i"
                            [projects]="contributing"
                            [owner]="false">
        </app-project-button>
      </div>
    </div>

  </div>

  <div *ngIf="user404">
    <h1>User Not Found</h1>
    Hmmm, nobody has the name <b><u>{{username}}</u></b> yet...
  </div>

</div>
