<div class="container">
  <h1>{{heading}}</h1>

  <div *ngIf="success"
       role="alert"
       class="alert alert-success">
    Success!
  </div>

  <div *ngIf="fail"
       role="alert"
       class="alert alert-danger">
    Username and/or password is incorrect.
  </div>

  <div class="form-group row">

    <div class="col-sm-5">
      <input type="text"
             class="form-control"
             [(ngModel)]="username"
             placeholder="Username" />
    </div>

    <div class="col-sm col-form-label">
      <a class="text-secondary"
         routerLink="/forgot/username/"
         style="font-size: 0.8rem">Forgot username?</a>
    </div>

  </div>

  <div class="form-group row">

    <div class="col-sm-5">
      <input type="password"
             class="form-control"
             [(ngModel)]="password"
             placeholder="Password" />
    </div>

    <div class="col-sm col-form-label">
      <a class="text-secondary"
         routerLink="/forgot/password"
         style="font-size: 0.8rem">Forgot password?</a>
    </div>

  </div>
  <button class="btn btn-primary"
          (click)="login()">
    Log In
  </button>
</div>
