<div class="container">

  <h1>{{heading}}</h1>

  <!--Closable alert if set-->
  <ngb-alert *ngIf="alert !== undefined"
             [type]="alert.type"
             (close)="setAlert(-1, '')"
             style="white-space: pre-line">
    {{alert.message}}
  </ngb-alert>

  <!--Hidden default file input with file selection handling-->
  <input #realFileInput
         id="profilepic"
         type="file"
         accept="image/*"
         (change)="onFileSelect($event)"
         style="display:none;" />

  <!--Custom file input-->
  <div class="form-group row">
    <label class="col-form-label col-sm-2">Profile Picture</label>
    <div class="col-sm-5">
      <div class="input-group">

        <input readonly
               id="picInput"
               class="form-control"
               placeholder="Profile Picture"
               value="{{fileName}}" />
        <div class="input-group-append">

          <!--Custom file input browse button uses angular template system-->
          <button class="btn btn-secondary"
                  (click)="realFileInput.click()">
            Browse
          </button>
        </div>

      </div>
    </div>

    <!--File too big error-->
    <div *ngIf="fileTooBig"
         class="col-sm col-form-label text-danger"
         style="font-size: 0.8rem">
      Sorry! The maximum file size is 1 MB.
    </div>

  </div>

  <!--Displays and binds input in inputs-->
  <div *ngFor="let input of inputs; index as i"
       class="form-group row">
    <label class="col-form-label col-sm-2">{{input.label}}</label>

    <div class="col-sm-5">
      <input id="{{input.id}}"
             class="form-control {{input.valid}}"
             [(ngModel)]="input.value"
             placeholder="{{input.label | titlecase}}"
             type="{{ input.id === 'password' || input.id === 'passwordconf' ? 'password' : 'text' }}"
             (change)="validate(i)" />
    </div>

    <!--Displays error for an input, if it has one-->
    <div *ngIf="input.error !== ''"
         class="col-sm col-form-label text-danger"
         style="font-size: 0.8rem">
      {{input.error}}
    </div>

  </div>

  <!--Skill builder component-->
  <app-skills [skills]="skills"
              [readOnly]="false"></app-skills>

  <!--Offset sign up button-->
  <div class="row">
    <div class="col-sm offset-sm-2">
      <button class="btn btn-primary"
              (click)="signUp()">
        Sign Up
      </button>
    </div>
  </div>

</div>
